import {FlowRendererPlugin1} from './flowRendererPlugin1'
import {ReactFlowInstance, Rect, Viewport} from 'reactflow'
import {
    AViewerPluginEventMap,
    DynamicDrawUsage,
    ExtendedCopyPass,
    ExtendedShaderMaterial,
    HalfFloatType,
    InstancedBufferAttribute,
    LinearSRGBColorSpace,
    MaterialExtension,
    NoBlending,
    NoColorSpace,
    NormalBlending,
    RGBAFormat,
    serialize,
    shaderReplaceString,
    SRGBColorSpace,
    ThreeViewer,
    Vector2,
    Vector4,
    WebGLRenderTarget
} from 'threepipe'
import {FlowEdgeType, FlowNodeType, NodePreviewTargetType, RenderTargetType} from '../rendering'
import {glsl} from 'ts-browser-helpers'
import {PreviewNodeData} from '../../nodes/data/PreviewNodeData'
import {FullScreenQuadInstanced} from "../fsQuad";
import {onPointerDown, onPointerMove, onPointerUp, onPointerWheel} from "../mouse";

export const FLOW_NODE_PREVIEW_Y = -30
// export const FLOW_NODE_PREVIEW_PADDING = 10
export const FLOW_NODE_PREVIEW_PADDING = 0 // todo: set to 10

export abstract class FlowViewportRendererPlugin1<E extends AViewerPluginEventMap = AViewerPluginEventMap> extends FlowRendererPlugin1<E> {
    get lastCanvasRect(): DOMRect {
        return this._lastCanvasRect
    }

    get screenTarget(): RenderTargetType {
        return this._screenTarget
    }

    public static readonly PluginType: string = 'FlowViewportRendererPlugin1'
    enabled = true

    constructor(public flowInstance: ReactFlowInstance) {
        super()
    }

    @serialize()
    viewport: Viewport = {x: 0, y: 0, zoom: 1}

    protected _lastCanvasRect: DOMRect = new DOMRect()

    protected _nodesPreviewRendered: FlowNodeType[] = []
    protected _screenTarget: RenderTargetType = new WebGLRenderTarget(1, 1)
    // node_id, viewport(on screen)
    protected _renderedViewports: [string, Vector4][] = []  // current viewports
    // node_id, viewport(on screen target), targetViewport(on screen)
    protected _renderedRects: [string, Vector4, Rect][] = []  // when rendered on screenTarget
    get renderedViewports() {
        return this._renderedViewports
    }

    nodeToViewport(rect: Rect): Vector4 | null {
        const top = FLOW_NODE_PREVIEW_Y  // moving up a bit (because of roundedness)
        // no padding if the node is too small otherwise it wont be shown
        const padding = (Math.min(rect.width, rect.height) < (10 * FLOW_NODE_PREVIEW_PADDING)) ? 0 : FLOW_NODE_PREVIEW_PADDING
        const vp = new Vector4(
            (rect.x + padding/2) * this.viewport.zoom + this.viewport.x,
            (this._lastCanvasRect.height - (rect.y - padding/2) * this.viewport.zoom - this.viewport.y) - top * this.viewport.zoom,
            (rect.width-padding) * this.viewport.zoom,
            (rect.height-padding) * this.viewport.zoom,
        )
        return vp.x > this._lastCanvasRect.width || vp.y > this._lastCanvasRect.height || vp.x + vp.z < 0 || vp.y + vp.w < 0 || vp.z < 0 || vp.w < 0 ? null : vp
    }

    private _gammaCorrectMaterial = new ExtendedCopyPass((v)=>`${v} = LinearTosRGB(${v});`, true).material

    renderNodePreview(node: FlowNodeType<PreviewNodeData>, target?: NodePreviewTargetType, clear = false) {
        if (!this._viewer) return false
        if (!node.data.preview || this._nodesPreviewRendered.includes(node)) return false
        // todo: pass the renderer instead in the function instead of looping again.
        const renderer = this.nodeRenderers.find(r => (!r.type || r.type === node.type) && (!r.canRender || r.canRender(node)))
        if (!target) {
            target = renderer?.getPreviewTarget ? renderer.getPreviewTarget(node, this) : undefined
        }
        if(!target) return false
        const rect = {
            x: node.position.x,
            y: node.position.y,
            width: node.width || 0,
            height: (node.width || 0) * (target.height / target.width)
        }
        // this returns null when node is out of screen
        const viewport = this.nodeToViewport(rect)
        // console.log(viewport, target.width, target.height)
        if (viewport) {
            if(renderer?.beforeRenderToScreen){
                target = renderer.beforeRenderToScreen(node, this, target)
            }
            // vs.viewer.renderManager.composer.copyPass2.material.defines.OPAQUE = '1'
            // todo make a mode in UI to cycle this.
            const renderAnyway = false
            const gammaCorrect = false
            // console.log(target.texture.name, target.texture.colorSpace)
            const mat = gammaCorrect ?{
                respectColorSpace: true, // this is required because transparent doesnt work when respectColorSpace is false.
                material: this._gammaCorrectMaterial,
            } : {respectColorSpace: true}
            const cs = this._screenTarget.texture.colorSpace
            this._screenTarget.texture.colorSpace = LinearSRGBColorSpace
            this._screenTarget.texture.needsUpdate = true
            this._viewer.renderManager.blit(this._screenTarget, {
                source: target.texture,
                viewport, clear,
                transparent: target.texture.colorSpace === NoColorSpace && !renderAnyway,
                blending: NoBlending,
                ...mat
            })
            this._screenTarget.texture.colorSpace = cs
            if(Math.max(target.texture.image.width, target.texture.image.height) < 50) {
                gridMat.uniforms.vSize.value.set(target.texture.image.width, target.texture.image.height)
                // render a line grid over the preview
                this._viewer.renderManager.blit(this._screenTarget, {
                    viewport, clear: false,
                    respectColorSpace: false,
                    transparent: true,
                    blending: NormalBlending,
                    material: gridMat,
                })
            }
            // console.log()
            if(!node.id) console.error('node.id is not set', node);
            this._renderedRects.push([node.id, viewport, rect])
            node.data.onPreviewRender(viewport, rect)
        }
        this._nodesPreviewRendered.push(node)
        return !!viewport
    }

    onAdded(viewer: ThreeViewer) {
        super.onAdded(viewer);

        // todo remove events
        viewer.container.parentElement?.addEventListener('pointerdown', (e) => onPointerDown(this, e))
        viewer.container.parentElement?.addEventListener('pointerup', (e) => onPointerUp(this, e))
        viewer.container.parentElement?.addEventListener('pointermove', (e) => onPointerMove(this, e))
        viewer.container.parentElement?.addEventListener('wheel', (e) => onPointerWheel(this, e), {passive: false, capture: true})

        this._screenTarget = viewer.renderManager.createTarget<WebGLRenderTarget>({
            sizeMultiplier: 1,
            type: HalfFloatType,
            colorSpace: LinearSRGBColorSpace,
            // colorSpace: SRGBColorSpace,
            format: RGBAFormat,
            depthBuffer: false,
            generateMipmaps: false,
        })
        viewer.renderManager.screenPass.overrideReadBuffer = this._screenTarget
        // viewer.renderManager.screenPass.outputColorSpace = LinearSRGBColorSpace
        viewer.renderManager.screenPass.outputColorSpace = SRGBColorSpace

        if(this.useInstances) {
            const ext: MaterialExtension = {
                uuid: 'FLOW_VIEWPORT_RENDERER',
                parsVertexSnippet: `
            attribute vec4 instanceSrcRect;  // (x, y, width, height) in UV space
            attribute vec4 instanceDstRect;  // (x, y, width, height) in screen space
            varying vec4 vSrcRect;
            `,
                parsFragmentSnippet: `
            varying vec4 vSrcRect;
            `,
                shaderExtender: (shader) => {
                    shader.fragmentShader = shaderReplaceString(shader.fragmentShader, 'vUv)', 'customUv)', {replaceAll: true})
                    // // Map vUv (quad local space) to the source texture
                    shader.fragmentShader = shaderReplaceString(shader.fragmentShader, 'void main(){', `
                    vec2 customUv = vSrcRect.xy + vSrcRect.zw * vUv;
                    if(customUv.x > 1. || customUv.y > 1. || customUv.x < 0. || customUv.y < 0.) discard;
                    // vec2 customUv = vUv;
                    // gl_FragColor = vec4(1,0,0,1);
                    // return;
                `, {append: true})
                    shader.vertexShader = shaderReplaceString(shader.vertexShader, 'void main() {', `
                    vSrcRect = instanceSrcRect;
                    // Convert instanceDstRect from normalized space to screen space
                    vec2 pos = instanceDstRect.xy + (position.xy + 1.0) * 0.5 * instanceDstRect.zw;
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos * 2.0 - 1., 0., 1.0 );
                    return;
                `, {append: true})
                },
                computeCacheKey: () => '1',
                isCompatible: (mat) => true
            }
            viewer.renderManager.screenPass.material.registerMaterialExtensions([ext])

            const fsQuad = new FullScreenQuadInstanced(viewer.renderManager.screenPass.fsQuad.material, this.maxCount)
            // Create instance attributes for srcRects & dstRects
            fsQuad.mesh.geometry.setAttribute("instanceSrcRect", this._sourceViewportRectAttr);
            fsQuad.mesh.geometry.setAttribute("instanceDstRect", this._destViewportRectAttr);
            viewer.renderManager.screenPass.fsQuad = fsQuad
        }
    }

    private readonly useInstances = false // todo
    private readonly frameWaitCount = 8
    private readonly maxCount = 100
    private _sourceViewportRectAttr = new InstancedBufferAttribute(new Float32Array(this.maxCount * 4), 4).setUsage(DynamicDrawUsage)
    private _destViewportRectAttr = new InstancedBufferAttribute(new Float32Array(this.maxCount * 4), 4).setUsage(DynamicDrawUsage)

    private _waitFrames = 0 // wait for x frames before rerendering the whole graph(on viewport change)

    protected _onPreFrame() {
        if(this._waitFrames > 0) {
            this._waitFrames--
            if(this._waitFrames === 0) {
                this.setDirty()
                return
            }
        }
        super._onPreFrame();
    }

    // private _viewportDirty = false
    setViewportDirty = () => {
        // if(this._dirty1) return
        if(this.useInstances) {
            this.viewer?.setDirty(this)
            this._waitFrames = this.frameWaitCount
        }else this.setDirty()
        // this._viewportDirty = true
    }

    private _renderStartTime = 0
    protected _onPreRender() {
        const v = this._viewer
        if (!v) throw new Error('Viewer not initialized')
        // console.log('Render time:', performance.now() - this._renderStartTime)
        this._renderStartTime = performance.now()

        this._renderedViewports = []
        this._lastCanvasRect = v.canvas.getBoundingClientRect() || this._lastCanvasRect

        if(this._dirty1) {
            v.renderManager.clearColor({
                r: 0,
                g: 0,
                b: 0,
                a: 0,
                target: this._screenTarget,
                depth: false,
                stencil: false
            })
            this._nodesPreviewRendered = []
            this._renderedRects = []
            super._onPreRender() // render all nodes
            this._waitFrames = 0
        }

        // update instance attributes
        let i = 0
        for (const renderedViewport of this._renderedRects) {
            const src = renderedViewport[1]
            this._sourceViewportRectAttr.array.set(src ? [src.x / this._screenTarget.width, src.y / this._screenTarget.height, src.z / this._screenTarget.width, src.w / this._screenTarget.height] : [0,0,0,0], 4 * i)
            const rect = renderedViewport[2]
            const dest = this.nodeToViewport(rect)
            this._destViewportRectAttr.array.set(dest ? [dest.x / this._screenTarget.width, dest.y / this._screenTarget.height, dest.z / this._screenTarget.width, dest.w / this._screenTarget.height] : [0,0,0,0], 4 * i)
            if(dest) this._renderedViewports.push([renderedViewport[0], dest])
            i++;
        }
        if(i < this.maxCount) {
            this._sourceViewportRectAttr.array.fill(0, 4 * i)
            this._destViewportRectAttr.array.fill(0, 4 * i)
        }
        this._sourceViewportRectAttr.needsUpdate = true
        this._destViewportRectAttr.needsUpdate = true
    }

    deleteElements(data: { nodes?: FlowNodeType[], edges?: FlowEdgeType[] }) {
        super.deleteElements(data)
        if (!this.flowInstance) return
        this.flowInstance.deleteElements(data)
    }

    addElements(data: { nodes?: FlowNodeType[], edges?: FlowEdgeType[] }) {
        super.addElements(data)
        if (!this.flowInstance) return
        data.nodes && this.flowInstance.addNodes(data.nodes)
        data.edges && this.flowInstance.addEdges(data.edges)
    }

    async importState(_state: any): Promise<void> {
        // const state = {..._state}
        await super.importState(_state);
        this.flowInstance.setViewport(this.viewport)
    }


}

export class GridMaterialTest extends ExtendedShaderMaterial{
    constructor() {
        super({
            uniforms: {
                vSize: {value: new Vector2(100, 100)},
                color: {value: new Vector4(1, 1, 1, 1)},
                gridSize: {value: 1},
            },
            vertexShader: glsl`
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: glsl`
                uniform vec4 color;
                uniform float gridSize;
                uniform vec2 vSize;
                varying vec2 vUv;
                void main() {
                    vec2 gridPos = vUv * vSize;
                    float lineThickness = gridSize * 0.1;

                    // Calculate if we are on a line
                    float isLineX = 1. - step(0.0, mod(gridPos.x, gridSize) - lineThickness) - step(gridSize - lineThickness, mod(gridPos.x, gridSize));
                    float isLineY = 1. - step(0.0, mod(gridPos.y, gridSize) - lineThickness) - step(gridSize - lineThickness, mod(gridPos.y, gridSize));
                    float isLine = max(isLineX, isLineY);

                    vec3 gridColor = mix(color.xyz, vec3(0.0), isLine);
                    gl_FragColor = vec4(gridColor, isLine);
                }
            `,
        }, [])
    }
}
const gridMat = new GridMaterialTest()
