import React from "react";
import {KTX2LoadPlugin, KTXLoadPlugin, ThreeViewer} from 'threepipe'
import {lygiaMaterialExtension} from '../utils/importers/lygia'

export type ThreeViewerComponentProps = React.PropsWithChildren<{
    style?: React.CSSProperties
    onMount: (viewer: ThreeViewer, first: boolean) => void
}>
export type ThreeViewerComponentState = {}
export const ThreeViewerContext = React.createContext<ThreeViewer | null>(null);
// todo like this https://github.com/wbkd/react-flow/blob/main/packages/core/src/components/ReactFlowProvider/index.tsx
// export const ThreeViewerProvider = ThreeViewerContext.Provider;
export class ThreeViewerComponent extends React.Component<ThreeViewerComponentProps, ThreeViewerComponentState> {
    private _container: React.RefObject<HTMLDivElement>;
    public viewer: ThreeViewer | null;

    constructor(props: ThreeViewerComponentProps) {
        super(props);
        this._container = React.createRef<HTMLDivElement>()
        this.viewer = null
    }

    private async _setupViewer(container: HTMLElement) {
        if (this.viewer) {
            this.viewer.canvas.remove()
            container.appendChild(this.viewer.canvas)
            return false
        }

        const canvas = document.createElement('canvas')
        canvas.style.width = '100%'
        canvas.style.height = '100%'
        canvas.style.maxWidth = '100%'
        canvas.style.maxHeight = '100%'
        container.appendChild(canvas)
        // Initialize the viewer
        this.viewer = new ThreeViewer({
            canvas, // todo props
            rgbm: false,
            dropzone: false,
            tonemap: false,
            debug: true,
        });
        this.viewer.addPluginSync(KTXLoadPlugin)
        this.viewer.addPluginSync(KTX2LoadPlugin)
        this.viewer.materialManager.registerMaterialExtension(lygiaMaterialExtension)
        return true
    }

    componentDidMount() {
        super.componentDidMount?.();
        if (!this._container.current) return;
        this._setupViewer(this._container.current).then((first) => {
            this.props.onMount(this.viewer!, first)
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount?.();
        if (this.viewer) this.viewer.canvas.remove()
    }

    render() {
        return (
            // <ThreeViewerContext.Provider value={this.viewer}>
            <div id="canvasContainer" style={{width: "100%", height: "100%", ...this.props.style}} ref={this._container}>
            {this.props.children}
            </div>
            // </ThreeViewerContext.Provider>
    )
    }

}

export function useThreeViewer() {
    return React.useContext(ThreeViewerContext)
}
