import {
    BufferGeometry,
    Float32BufferAttribute,
    InstancedMesh,
    Material,
    Mesh,
    OrthographicCamera,
    WebGLRenderer
} from "threepipe";

const _camera = new OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );
const _geometry = new BufferGeometry();
_geometry.setAttribute( 'position', new Float32BufferAttribute( [ - 1, 3, 0, - 1, - 1, 0, 3, - 1, 0 ], 3 ) );
_geometry.setAttribute( 'uv', new Float32BufferAttribute( [ 0, 2, 0, 0, 2, 0 ], 2 ) );

export class FullScreenQuad {
    protected _mesh: Mesh<BufferGeometry, Material>

    constructor( material: Material ) {

        this._mesh = new Mesh( _geometry, material );

    }

    dispose() {

        this._mesh.geometry.dispose();

    }

    render( renderer: WebGLRenderer ) {

        renderer.clear();
        renderer.render( this._mesh, _camera );

    }

    get material() {

        return this._mesh.material;

    }

    set material( value ) {

        this._mesh.material = value;

    }

}

export class FullScreenQuadInstanced extends FullScreenQuad{
    get mesh() {
        return this._mesh
    }
    constructor(material: Material, maxCount = 100) {
        super(material)
        console.log(this)
        this._mesh = new InstancedMesh(this._mesh.geometry, this._mesh.material, maxCount)
        // this._mesh.instanceMatrix.setUsage( DynamicDrawUsage ); // will be updated every frame
    }
}

