import {Box2, Vector2, Vector4} from 'threepipe'

import {FlowRendererPlugin1} from './plugins/flowRendererPlugin1'
import {FlowViewportRendererPlugin1} from "./plugins/flowViewportRendererPlugin1";

export function getMouseFromEvent(v: FlowViewportRendererPlugin1, e: PointerEvent|WheelEvent) {
    const viewports = v.renderedViewports
    const rect = v.viewer!.canvas.getBoundingClientRect()
    if(!viewports || !rect) return null
    const pos= new Vector2(
        e.clientX - rect.left,
        -e.clientY + rect.top + rect.height,
    )
    // console.log(viewports, pos, viewports[0].x, e.clientX, rect.top+rect.height-viewports[0].y, e.clientY)
    const box = new Box2()
    const vectorToBox = (v: Vector4) => {
        box.min.x = v.x
        box.min.y = v.y
        box.max.x = v.x + v.z
        box.max.y = v.y + v.w
        return box;
    }
    const vp = viewports.find(([_, vp]) => vectorToBox(vp).containsPoint(pos))
    if(vp) {
        const [node_id, viewport] = vp;
        return {
            node_id, viewport, position: new Vector2(
                (pos.x - viewport.x) / viewport.z,
                (pos.y - viewport.y) / viewport.w,
            )
        }
    }
    return null
}

const onlyOnPane = true // todo make variable, we need to disable when canvas is on top
export function onPointerDown(v: FlowViewportRendererPlugin1, e: PointerEvent){
    if(e.button !== 0 || !v.mouseState) return
    if(onlyOnPane && !(e.target as HTMLElement).classList?.contains('react-flow__pane')) return
    v.mouseState.isDown = false
    v.mouseState.isClick = false
    v.mouseState.scroll.delta.set(0,0,0,)
    const m = getMouseFromEvent(v, e)
    if(m) {
        v.mouseState.isDown = true
        v.mouseState.isClick = true
        v.mouseState.clickPosition.copy(m.position)
        v.mouseState.position.copy(m.position)
        v.mouseState.nodeId = m.node_id
        e.preventDefault() // for react flow.
        e.stopPropagation()
    }else {
        v.mouseState.nodeId = ''
    }
}

export function onPointerUp(v: FlowViewportRendererPlugin1, e: PointerEvent){
    if(e.button !== 0 || !v.mouseState) return
    v.mouseState.isDown = false
    v.mouseState.isClick = false
    v.mouseState.scroll.delta.set(0,0,0,)
    const m = getMouseFromEvent(v, e)
    if(m) {
        v.mouseState.position.copy(m.position)
        v.mouseState.nodeId = m.node_id
    }else {
        v.mouseState.nodeId = ''
    }
}

export function onPointerMove(v: FlowViewportRendererPlugin1, e: PointerEvent){
    // console.log(e)
    if(!v.mouseState) return
    v.mouseState.clientX = e.clientX
    v.mouseState.clientY = e.clientY
    v.mouseState.scroll.delta.set(0,0,0,)
    if(!v.mouseState.isDown) {
        v.mouseState.nodeId = ''
        return
    }
    const m = getMouseFromEvent(v, e)
    if(m) {
        v.mouseState.position.copy(m.position)
        v.mouseState.nodeId = m.node_id
    }else {
        v.mouseState.nodeId = ''
    }
}

export function onPointerWheel(v: FlowViewportRendererPlugin1, e: WheelEvent){
    if(!v.mouseState) return
    if(onlyOnPane && !(e.target as HTMLElement).classList?.contains('react-flow__pane')) return
    const maxPerSec = 20 // lagging scroll. todo: do damping similar to how wheel is handled.
    if(v.mouseState.scroll.time + 1000/maxPerSec > Date.now()) {
        e.preventDefault()
        e.stopPropagation()
        return
    }
    v.mouseState.clientX = e.clientX
    v.mouseState.clientY = e.clientY
    // if(!v.mouseState.isDown) return // todo only use wheel when certain key is pressed?
    const scroll = v.mouseState.scroll.delta.length() < 0.001 || v.mouseState.scroll.always
    // if(!scroll /*&& !v.mouseState.isDown*/) return // dont do this
    const m = getMouseFromEvent(v, e)
    if(m) {
        // if(scroll || v.mouseState.isDown) { // todo isDown?
        //     v.mouseState.position.copy(m.position)
        //     v.mouseState.nodeId = m.node_id
        // }
        v.mouseState.position.set(0,0)
        v.mouseState.clickPosition.set(0,0)
        v.mouseState.nodeId = m.node_id
        v.mouseState.scroll.delta.set(e.deltaX, e.deltaY, e.deltaZ)
        // console.log(v.mouseState.scroll.delta)
        v.mouseState.scroll.mode = e.deltaMode === WheelEvent.DOM_DELTA_PIXEL ? 'pixel' :
            e.deltaMode === WheelEvent.DOM_DELTA_LINE ? 'line' :
                e.deltaMode === WheelEvent.DOM_DELTA_PAGE ? 'page' : 'pixel';
        if(scroll) { // previous scroll event is not consumed, so ignore
            v.mouseState.scroll.time = Date.now() // time has to be in this
            v.setViewportDirty()
            e.preventDefault()
            e.stopPropagation()
        }
    }else {
        // v.mouseState.scroll.delta.set(0,0,0,)
        v.mouseState.nodeId = ''
    }
}
