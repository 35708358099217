import {Camera, ICamera, OrthographicCamera, PerspectiveCamera, Spherical, Vector3} from 'threepipe'
import {FlowMouseState} from './state'

const _spherical = new Spherical()

export function updateNodeCamera(mouseState: FlowMouseState | undefined, camera: Camera, minDist: number, maxDist: number) {
    if (!mouseState) return

    const isScrolling = mouseState.scroll.delta.lengthSq() > 0.001

    if (!camera.userData.lastPos || mouseState.isClick || isScrolling)
        camera.userData.lastPos = (camera.userData.lastPos || new Vector3()).copy(camera.position)

    if (camera.userData.lastPos.length() < 0.001) camera.userData.lastPos.set(0, 0, minDist)
    _spherical.setFromVector3(camera.userData.lastPos)
    // _spherical.radius = Math.min(maxDist, Math.max(minDist, _spherical.radius)) // this is clamped later, shouldnt be here.

    if(isScrolling) { // only zoom when scrolling
        const scrollMultiplier = 2 * (mouseState.scroll.mode === 'pixel' ? 0.01 :
            mouseState.scroll.mode === 'line' ? 0.1 :
                mouseState.scroll.mode === 'page' ? 1 : 0.01)
        const scrollDelta = mouseState.scroll.delta.y * scrollMultiplier
        const rad = _spherical.radius
        // todo set zoom instead of position when orthographic camera.
        _spherical.radius = Math.min(maxDist, Math.max(minDist, _spherical.radius + scrollDelta))
        // console.log(_spherical.radius, _spherical.radius - rad, scrollDelta)

        // we dont need to set this if radius didn't change.
        //  todo but this is weird because of automatic damping in macos.
        const del = Math.abs(_spherical.radius - rad)
        if(del > 0 /*|| (del === 0 && Math.abs(scrollDelta)>0.001)*/) {
            mouseState.scroll.delta.set(0, 0, 0)
        }

    }else {
        const mouseStart = mouseState.clickPosition // from 0 to 1
        const mouse = mouseState.position // from 0 to 1
        _spherical.phi += Math.PI * -(mouseStart.y - mouse.y)
        _spherical.theta += Math.PI * (mouseStart.x - mouse.x)
    }

    camera.position.setFromSpherical(_spherical)
    // console.log(camera.position, mouseState, _spherical)
    if((camera as ICamera).target)
        camera.lookAt((camera as ICamera).target)
    else camera.lookAt(0, 0, 0)

    if((camera as ICamera).setDirty) (camera as ICamera).setDirty()
    else (camera as ICamera).dispatchEvent({type: 'cameraUpdate', source: 'updateNodeCamera', bubbleToParent: true}) // source is not used

    if(isScrolling)
        camera.userData.lastPos.copy(camera.position)
}

export function updateCameraAspect(aspect: number, camera: Camera) {
    if ((camera as PerspectiveCamera).isPerspectiveCamera && camera.userData.autoAspect !== false) {
        (camera as PerspectiveCamera).aspect = aspect;
        (camera as PerspectiveCamera).updateProjectionMatrix()
    }
    if ((camera as OrthographicCamera).isOrthographicCamera && camera.userData.autoFrustumSize !== false) {
        ;(camera as OrthographicCamera).left = aspect * -1
        ;(camera as OrthographicCamera).right = aspect
        ;(camera as OrthographicCamera).top = 1
        ;(camera as OrthographicCamera).bottom = -1
        ;(camera as OrthographicCamera).updateProjectionMatrix()
    }
}
