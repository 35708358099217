import React, {DragEventHandler, useCallback, useEffect} from 'react';
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    BackgroundVariant,
    Connection,
    ConnectionMode,
    DefaultEdgeOptions,
    OnConnect,
    OnEdgesChange,
    OnEdgeUpdateFunc,
    OnNodesChange,
    SelectionMode,
    updateEdge,
    useOnViewportChange,
    useReactFlow,
    Viewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {lerp, LinearSRGBColorSpace, mobileAndTabletCheck, SRGBColorSpace, ThreeViewer} from 'threepipe'
import {ThreeViewerComponent, ThreeViewerContext} from './components/ThreeViewerComponent'
import {initViewerState} from './utils/flow'
import {getShaderErrorString} from './utils/shadererrors'
import {FlowProjectPlugin1} from './utils/plugins/flowProjectPlugin1'
import {DBConnection} from './utils/idb'
import {WelcomeScreenDialog} from './components/WelcomeScreenDialog'
import {ServerDBConnection} from './components/ServerDBConnection'
import {useFlowContext} from './contexts/FlowContext'
import {ProjectNavbar} from './components/ProjectNavbar'
import {ColorPickerCard, cycleColorPickerMode, pickCanvasColor} from './components/ColorPickerCard'
import SelfConnectingEdge3 from './edges/SelfConnectingEdge3';
import {DialogConnection} from './utils/DialogConnection'
import {initConnection} from './utils/plugins/flowRendererPlugin1'
import {ShaderErrorContainer} from './components/ShaderErrorContainer'
import {useShaderCodeEditor} from './components/ShaderCodeEditor'
import {useAutosave} from './utils/UseAutosave'
import {NodeTypeDefs} from './NodeTypeDefs'
import {allowedDirectConnectionTypes, allowedMultipleEdgeTypes} from './nodes/data/NodeData'
import {FlowEdgeType, FlowNodeType} from './utils/rendering'
import {ProjectVersionsDialog} from './components/ProjectVersionsDialog'
import {useGlobalSettings} from './contexts/GlobalSettings'
import DefaultEdge from './edges/DefaultEdge'
import {Classes, Popover} from '@blueprintjs/core'
import {AddNodeMenu} from './components/AddNodeMenu'
import {Offset} from '@blueprintjs/core/lib/esnext/components/context-menu/contextMenuShared'

const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: false,
};

const edgeTypes = {
    // bidirectional: BiDirectionalEdge,
    // selfConnecting: SelfConnectingEdge,
    selfConnecting: SelfConnectingEdge3,
    // buttonedge: ButtonEdge,
    default: DefaultEdge,
};

const nodeTypes = Object.fromEntries(Object.entries(NodeTypeDefs).map(([key, value]) => [key, value.Component]))

function isValidConnection(connection: Connection, edges: FlowEdgeType[]) {
    const src = connection.sourceHandle?.split('Out')[0]
    const trg = connection.targetHandle?.split('In')[0]

    // check if connection to target already exists
    const existingTargetEdge = edges.findIndex(e => e.targetHandle === connection.targetHandle && e.target === connection.target) > -1
    if (!src || (existingTargetEdge && !allowedMultipleEdgeTypes.includes(src as any)))
        return false

    return (src && src === trg && (allowedDirectConnectionTypes.includes(src as any))) || // both textures
        ( // buffer -> texture and no other buffer -> buffer connection exists.
            src === 'buffer' && trg === 'texture' &&
            edges.findIndex(e => (e.sourceHandle === connection.sourceHandle && e.source === connection.source) && e.targetHandle?.split('In')[0] === 'buffer') === -1
        ) ||
        ( // buffer -> buffer and no other connection exists on both handles
            src === 'buffer' && trg === 'buffer' &&
            edges.findIndex(e => e.sourceHandle === connection.sourceHandle && e.source === connection.source) === -1
        )
    // todo: self loop check
}

const isMobile = mobileAndTabletCheck();

function Flow() {

    const {nodes, setNodes, edges, setEdges, viewport, setViewport, rerenderNode} = useFlowContext()

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => {
                // console.log(changes, nds.filter(n => changes.find(c => (c as any).id === n.id)))
                const allDimensions = changes.every(c=>c.type === "dimensions")
                if(allDimensions){
                    // react flow sends event unnessarily even when width and height dont change
                    const initSizes = nds.filter(n => {
                        const c = (changes.find(c => (c as any).id === n.id))
                        if(!c) return
                        return {width: n.width||0, height: n.height||0}
                    })
                    const res = applyNodeChanges(changes, nds)
                    const changedSizes = res.filter(n => {
                        const c = (changes.find(c => (c as any).id === n.id))
                        if(!c) return
                        return {width: n.width||0, height: n.height||0}
                    })
                    const hasChanged = initSizes.some((n, i) => {
                        const c = changedSizes[i]
                        return c.width !== n.width || c.height !== n.height
                    })
                    // console.log(hasChanged)
                    if(!hasChanged) return nds
                    return res
                }else {
                    return applyNodeChanges(changes, nds)
                }
            })
        },
        [setNodes]
    );
    const onEdgesChange: OnEdgesChange = useCallback(
        (changes) => {
            // console.log('onEdgesChange', changes)
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [setEdges, /*nodes*/]
    );

    const onConnect: OnConnect = useCallback(
        (connection) => {
            // console.log('onConnect', connection)
            setEdges((eds) => addEdge(initConnection(connection), eds))
            connection.target && rerenderNode(connection.target)
        },
        [setEdges, rerenderNode, /*nodes*/]
    );
    // const onEdgesDelete: OnEdgesDelete = useCallback(
    //     (edges) => {
    //         console.log('onEdgesDelete', edges)
    //         // setEdges((eds) => addEdge(initConnection(edges), eds))
    //     },
    //     [setEdges, /*nodes*/]
    // );

    const edgeUpdateSuccessful = React.useRef(true);

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate: OnEdgeUpdateFunc = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;
        // console.log('onEdgeUpdate', oldEdge, newConnection)
        setEdges((els) => updateEdge(oldEdge, initConnection(newConnection) as Connection, els));
    }, []);

    // this is edge delete basically.
    const onEdgeUpdateEnd = useCallback((_: MouseEvent | TouchEvent, edge: FlowEdgeType) => {
        if (!edgeUpdateSuccessful.current) {
            // console.log('onEdgeUpdateEnd', edge)
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
            edge.target && rerenderNode(edge.target)
        }

        edgeUpdateSuccessful.current = true;
    }, [rerenderNode]);

    const targetWheelZoom = React.useRef({zoom: 1, time: 0, last: 1})

    const {viewer, setViewer,
        project, setProject,
        keepDirty, setKeepDirty, plugin} = useFlowContext()

    useOnViewportChange({
        // todo start and end dont work in this version? only when its animating, not mouse...
        // onStart: useCallback((viewport: Viewport) => console.log('start', viewport), []),
        onChange: useCallback((viewport: Viewport) => {
            setViewport(viewport)
            if(plugin) {
                plugin.viewport = viewport
                plugin.setViewportDirty()
            }
            // this has to be in onChange and cannot be in onEnd.
            if(targetWheelZoom.current.time < Date.now() - 500) { // 0.5s = max zoom time per scroll event
                targetWheelZoom.current.zoom = viewport.zoom
            }
            targetWheelZoom.current.last = viewport.zoom
            // console.log('change', viewport)
        }, [setViewport, targetWheelZoom, plugin]),
        // onEnd: useCallback((viewport: Viewport) => console.log('end', viewport), []),
    });

    const reactFlowInstance = useReactFlow();

    const reactFlow = React.useRef<HTMLDivElement | null>(null);
    const viewerComponent = React.useRef<ThreeViewerComponent | null>(null)
    const [mousePosition, setMousePosition] = React.useState<{ x: number, y: number }>({x: 0, y: 0})

    const currentTimeLabel = React.useRef<HTMLSpanElement | null>(null)
    const currentMouseTooltip = React.useRef<HTMLDivElement | null>(null)

    const {setShaderError} = useShaderCodeEditor()

    useEffect(() => {
        if (!plugin) return
        plugin.nodes = nodes
        plugin.edges = edges
        plugin.setDirty()
    }, [plugin, nodes, edges])

    useEffect(() => {
        if (!plugin) return
        if (project) plugin.project = project
        plugin.keepDirty = keepDirty
        plugin.flowInstance = reactFlowInstance
        plugin.setDirty()
    }, [plugin, project, keepDirty, reactFlowInstance])

    // useEffect(() => {
    //     if (!plugin) return
    //     plugin.viewport = viewport
    //     plugin.setViewportDirty()
    // }, [plugin, viewport])

    const onViewerMount = useCallback(async (v: ThreeViewer, first?: boolean) => {
        if (v === viewer) return
        if (!v.getPlugin(FlowProjectPlugin1)) {
            // console.log(Serialization.SerializableClasses)
            setViewer(initViewerState(v, reactFlowInstance))

            const plugin = v.getPlugin(FlowProjectPlugin1)!

            const onImport = () => {
                // console.log('import', plugin.nodes, plugin.edges, plugin.project)
                setKeepDirty(plugin.keepDirty)
                setProject(plugin.project)
                // console.log(plugin.project)
            }
            onImport() // once to sync

            plugin.addEventListener('projectChange', onImport)

            plugin.userData.colorPickerMode = 'RGBA'
            v.container.parentElement?.addEventListener('pointerdown', (e) => {
                if (!plugin.userData.colorPickerMode || !currentMouseTooltip.current) return
                if (e.button === 2) { // right click
                    cycleColorPickerMode(plugin)
                }
            })

            v.addEventListener('postFrame', () => {
                if (currentTimeLabel.current) currentTimeLabel.current.innerHTML =
                        `${((plugin.timeState.time) / 1000).toFixed(2)} [${plugin.timeState.frame}]`
                if (currentMouseTooltip.current) currentMouseTooltip.current.innerHTML = pickCanvasColor(v, plugin)

                if(Math.abs(reactFlowInstance.getZoom() - targetWheelZoom.current.zoom) > 0.001) {
                    reactFlowInstance.zoomTo(lerp(reactFlowInstance.getZoom(), targetWheelZoom.current.zoom, 0.1))
                }
            })
            v.renderManager.renderer.debug.onShaderError = (gl, program, glVertexShader, glFragmentShader) => {
                const error = getShaderErrorString(gl, program, glVertexShader, glFragmentShader)
                console.error('Shader error', error)
                setShaderError(error)
            }

        }
    }, [reactFlowInstance, setKeepDirty, setProject, setShaderError, setViewer, viewer])

    const dragEvent: DragEventHandler = useCallback((e) => {
        if (!viewer) return
        viewer.canvas.dispatchEvent(new DragEvent(e.nativeEvent.type, e.nativeEvent))
        e.stopPropagation()
        e.preventDefault()
    }, [viewer])

    // todo make global setting for intervals.
    useAutosave(60*1000)

    // supabase
    // const [session, setSession] = React.useState<AuthSession | null>(null)

    const enableSmoothWheelZoom = false // todo - we also need to pan when zooming
    // custom wheel zoom logic, because it pans even on mouse wheel which is weird and there is no damping with ctrl key
    useEffect(() => {
        const onWheel = (e: WheelEvent)=>{
            if(!enableSmoothWheelZoom) return
            if(e.deltaX) return
            if(!e.deltaY) return
            if(e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return // standard react flow behaviour when any key is pressed
            // console.log(e.deltaY, (e as any).wheelDeltaY, e.deltaMode)
            if(
                // mac is unrealiable, sometimes int, sometimes not
                // (!Number.isSafeInteger(e.deltaY)) || // mac trackpad gives integer, except when it doesn't sometimes
                (Math.abs((e as any).wheelDeltaY) % 120 < 1 && Math.abs((e).deltaY) !== 40) || // testing on mx master 3 on windows. Mac also sometimes give 120 wheel delta but then deltaY is 40
                e.deltaMode !== 0
            ) {
                // console.log(e)
                e.preventDefault()
                e.stopPropagation()
                targetWheelZoom.current.zoom = (targetWheelZoom.current.last * (1 + Math.max(Math.min(-e.deltaY*0.1, 0.35), -0.35)))
                targetWheelZoom.current.time = Date.now()
                return
            }
        }
        const flow = reactFlow.current?.querySelector('.react-flow__renderer') as HTMLElement
        flow?.addEventListener('wheel', onWheel, {passive: false, capture: true})
        return ()=>{
            flow?.removeEventListener('wheel', onWheel)
        }
    }, [enableSmoothWheelZoom, reactFlow, targetWheelZoom])
    const {ui, interaction, rendering} = useGlobalSettings()

    // todo move somewhere else?
    if(viewer){
        const output = rendering.gammaCorrection ? SRGBColorSpace : LinearSRGBColorSpace
        if(output !== viewer.renderManager.screenPass.outputColorSpace) {
            viewer.renderManager.screenPass.outputColorSpace = output
            viewer.setDirty()
        }
    }
    const [paneContextMenu, setPaneContextMenu] = React.useState<Offset | null>(null)
    const contextMenuTargetRef = React.useRef<HTMLDivElement | null>(null)

    // const handleClose = React.useCallback(() => {
    //     setIsOpen(false);
    //     hideContextMenu();
    // }, []);

    // https://reactflow.dev/learn/advanced-use/computing-flows
    // noinspection PointlessBooleanExpressionJS
    return (
        <ThreeViewerContext.Provider value={viewer || null}>
            <ServerDBConnection/>
            <DBConnection/>
            <ProjectNavbar currentTimeLabelRef={currentTimeLabel}/>
            <ReactFlow
                ref={reactFlow}
                className={'react-flow ' +
                    (ui.edges_visible===false ? 'hide-edges ':'') +
                    (ui.handles_visible===false ? 'hide-handles ':'') +
                    (ui.nodes_visible===false ? 'hide-nodes ':'') +
                    (ui.edge_colors===false ? 'no-edge-colors ':'') +
                    (ui.node_colors===false ? 'no-node-colors ':'') +
                    (ui.handle_colors===false ? 'no-handle-colors ':'')
                }
                nodes={nodes}
                connectionMode={ConnectionMode.Loose}
                edges={edges}
                onlyRenderVisibleElements={true}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                // onEdgesDelete={onEdgesDelete}
                isValidConnection={(c)=>isValidConnection(c, edges)}
                onDragOver={dragEvent}
                onDrop={dragEvent}
                // fitView // this breaks flowInstance.setViewport
                // fitViewOptions={fitViewOptions}
                defaultEdgeOptions={defaultEdgeOptions}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                elevateNodesOnSelect={true}
                elevateEdgesOnSelect={true}
                defaultViewport={viewport}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                selectNodesOnDrag={true} // select the node when node is dragging

                onPaneContextMenu={(e) => {
                    // console.log(e)
                    e.preventDefault()
                    // e.stopPropagation()
                    setPaneContextMenu({
                        left: e.clientX,
                        top: e.clientY,
                    })
                }}

                onNodesDelete={(nodes: FlowNodeType[]) => {
                    // todo show confirmation dialog etc and see if anything else is required
                    nodes.forEach((node)=>node.data.onNodeDelete())
                }}

                panOnScroll={interaction.panOnScroll ?? true}
                zoomOnScroll={interaction.zoomOnScroll ?? false} // todo make setting
                // preventScrolling={true}
                zoomOnPinch={interaction.zoomOnPinch ?? true}
                minZoom={0.1}
                maxZoom={10}
                panOnDrag={(interaction.leftMouseDrag||isMobile) ? true : [1, 2]}
                selectionOnDrag={!interaction.leftMouseDrag && interaction.selectOnDrag} // selection can also be done with shift
                selectionMode={SelectionMode.Partial}

                onMouseMove={(e) => {
                    setMousePosition({x: e.clientX, y: e.clientY})
                    // console.log(e.clientX, e.clientY)
                    // console.log(reactFlowInstance.project({x: e.clientX, y: e.clientY}))
                }}

                deleteKeyCode={null}
                onKeyDownCapture={(e) => {
                    // console.log(e.key,e.target, (e.target as HTMLElement).parentElement)
                    if(!(e.target as HTMLElement).classList?.contains('react-flow__node')) return
                    // e.preventDefault() // no
                    e.stopPropagation()
                }}
                onMouseDownCapture={(e) => {
                    // content editable or input
                    if(!(e.target as HTMLElement).isContentEditable && !['INPUT', 'TEXTAREA', 'SELECT', 'MATH-FIELD'].includes((e.target as HTMLElement).tagName)) return
                    // console.log(e.button, e.target, (e.target as HTMLElement).tagName, (e.target as HTMLElement).isContentEditable)
                    // e.preventDefault() // no
                    e.stopPropagation()
                }}

                // zoomOnDoubleClick={false}
                // zoomOnScroll={false}
                // zoomOnPinch={false}
                children={
                    <>
                        {/*<Background id="1" gap={10} color="#777777" variant={BackgroundVariant.Lines} />*/}
                        {/*<Background id="2" gap={100} offset={1} color="#aaaaaa" variant={BackgroundVariant.Lines} />*/}
                        <Background id="2" gap={50} offset={1} color="#77777777" variant={BackgroundVariant.Dots}/>
                        <ThreeViewerComponent key="viewer" ref={viewerComponent} style={{
                            position: 'absolute', top: 0, left: 0,
                            pointerEvents: 'none',
                        }} onMount={onViewerMount}>
                        </ThreeViewerComponent>
                    </>

                }
            />
            <Popover
                isOpen={!!paneContextMenu}
                onClose={() => setPaneContextMenu(null)}
                content={paneContextMenu ? <div style={{position: "absolute", ...paneContextMenu}}>
                    <AddNodeMenu/> {/*todo: add the node to the correct position based on mouse */}
                </div> : <></>}
                rootBoundary="viewport"
                // portalContainer={document.body}
                positioningStrategy="absolute"
                usePortal={true}
                enforceFocus={false}
                placement="right-start"
                onInteraction={(nextOpenState: boolean) => {
                    if (!nextOpenState) {
                        setPaneContextMenu(null);
                    }
                }}
                hasBackdrop={true}
                backdropProps={{ className: Classes.CONTEXT_MENU_BACKDROP,
                    onClick: () => setPaneContextMenu(null),
                    onContextMenu: (e) => {
                        setPaneContextMenu(null)
                        e.preventDefault()
                    },
                }}
                canEscapeKeyClose={true}
                minimal={true}
                renderTarget={(ref)=> <></>}
                // targetOffset={paneContextMenu||undefined}
            />
            {/*<ContextMenuPopover></ContextMenuPopover>*/}

            <ShaderErrorContainer />

            <ColorPickerCard mousePosition={mousePosition} cardRef={currentMouseTooltip}/>

            <WelcomeScreenDialog onClose={() => {
                // setDialog({...dialog, isOpen: false})
            }}/>

            <ProjectVersionsDialog />

            <DialogConnection/>
        </ThreeViewerContext.Provider>
    );
}

export default Flow;
